<template>
  <div>
    <jzsy-header title="人员管理"></jzsy-header>
    <div class="wrapper">
      <ValidationObserver ref="form">
        <ValidationProvider rules="required" name="姓名" v-slot="{ errors }">
          <md-input-item
            class="input-item"
            type="text"
            placeholder="人员姓名"
            v-model="form.name"
            is-title-latent
            :maxlength="8"
            is-highlight
            title="姓名"
            :error="errors[0]"
            :disabled="loading"
          >
            <md-icon name="user" slot="left" color="#9579E5"></md-icon>
          </md-input-item>
        </ValidationProvider>
        <ValidationProvider
          rules="required|phone"
          name="手机号"
          v-slot="{ errors }"
        >
          <md-input-item
            class="input-item"
            type="phone"
            placeholder="人员手机号"
            v-model="form.mobile"
            is-title-latent
            :maxlength="11"
            is-highlight
            title="手机号"
            :error="errors[0]"
            :disabled="loading"
          >
            <md-icon name="mobile-phone" slot="left" color="#9579E5"></md-icon>
          </md-input-item>
        </ValidationProvider>
        <md-input-item
          class="input-item"
          type="text"
          placeholder="推荐码（建议与手机号一致）"
          v-model="referralCode"
          is-title-latent
          :maxlength="11"
          is-highlight
          title="推荐码"
          :disabled="true"
        >
          <md-icon name="id-card" slot="left" color="#9579E5"></md-icon>
        </md-input-item>
        <md-button
          class="submit-btn"
          type="primary"
          size="small"
          :plain="true"
          :loading="loading"
          @click="addHandle"
          >{{ btnText }}</md-button
        >
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import AdminApi from "@/api/admin";

export default {
  props: {
    staffId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      form: {
        staffId: "",
        name: "加载中...",
        mobile: "",
        referralCode: "",
      },
      loading: true,
      btnText: "加载中",
    };
  },
  computed: {
    referralCode: {
      get: function () {
        return this.form.referralCode
          ? this.form.referralCode
          : this.form.mobile;
      },
      set: function (value) {
        this.form.referralCode = !value ? "" : value.replace(/\s+/g, "");
      },
    },
  },
  watch: {
    $route: "fetchData",
  },
  methods: {
    addHandle() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          this.loading = true;
          AdminApi.updateStaff(this.form)
            .then(() => {
              this.$router.push("/staff/list-normal");
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    fetchData() {
      AdminApi.getStaff(this.staffId).then((res) => {
        this.form.staffId = res.data.staffId;
        this.form.name = res.data.name;
        this.form.mobile = res.data.mobile;
        this.form.referralCode = res.data.referralCode;
        this.loading = false;
        this.btnText = "保存";
      });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.right-nav-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92px;
  margin-right: 30px;
}
.wrapper {
  padding: 50px;
}
.password-tips {
  margin-top: 50px;
  color: #5a5a5a;
}
.submit-btn {
  margin-top: 50px;
}
</style>